.powered-by-container {
    text-align: right;
    font-size: 12px;
    font-family: 'Open Sans Variable', sans-serif;
}

.powered-by-container.rtl {
    text-align: left;
}

.powered-by-container span#company-name {
    font-family: 'Varela Round', sans-serif;
    text-transform: uppercase;
}

.powered-by-container a#powered-by-link {
    color: #5F6D7B;
}