.language-selector-container {
    text-align: left;
    font-weight: 500;
    color: #5F6D7B;
}

.language-selector-container.rtl {
    text-align: right;
}

.language-selector-container svg {
    height: 0.8em;
}

.language-selector-container select {
    font-family: 'Open Sans Variable', sans-serif;
    font-size: 12px;
    border: 0;
    outline: none;
    resize: none;
    background: unset;
    color: #5F6D7B;
}
